import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Preview
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";

const Page = styled.div`
  margin: 40px 0 0 0;

  @media (max-width: 900px) {
    margin: 55px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  max-width: 700px;

  & .glossary-entry-container {
    padding: 30px;
    margin: 0 0 30px 0;

    & h1 {
      font-size: 40px;
      line-height: 52px;

      @media (max-width: 768px) {
        font-size: 26px;
        line-height: 34px;
      }
    }

    & .excerpt-text {
      & p {
        &:last-of-type {
          margin-bottom: 10px;
        }

        & a {
          text-decoration: none;
        }
      }
    }
  }
`;

const colors = [
  "#b6ffff",
  "#f8c2ff",
  "#ffff8f",
  "#ffb592",
  "#c0ffc9",
  "#ffccd4",
  "#cfbe99",
];

function getRandomColor() {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
}

const Glossary = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType(`glossary`);
  }, []);

  const content = data.prismicGlossary.data.body.map((content, index) => {
    if (content.slice_type === "glossary_entry") {
      return (
        <div
          className="glossary-entry-container"
          key={`accordion_item_${index}_${content.id}`}
          style={{ backgroundColor: getRandomColor() }}
        >
          <div
            className="excerpt-title"
            dangerouslySetInnerHTML={{
              __html: content.primary.entry_title.html,
            }}
          />
          <div
            className="excerpt-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.entry_text.html,
            }}
          />
        </div>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicGlossary.data.title.text} | Three Rivers | Bexley`}
        meta={[
          {
            name: "title",
            content: `${data.prismicGlossary.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "og:title",
            content: `${data.prismicGlossary.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicGlossary.data.title.text} | Three Rivers | Bexley`,
          },
        ]}
      />
      <Page>
        <ContentContainer>
          <div className="introduction-text-container">
            <div
              className="section-text"
              dangerouslySetInnerHTML={{
                __html: data.prismicGlossary.data.text.html,
              }}
            />
          </div>

          <div className="content-container">{content}</div>
        </ContentContainer>
      </Page>
    </>
  );
};

export default withPreview(Glossary);

export const query = graphql`
  query {
    prismicGlossary {
      _previewable
      data {
        title {
          html
          text
        }
        text {
          html
          text
        }
        body {
          ... on PrismicGlossaryBodyGlossaryEntry {
            id
            slice_type
            primary {
              entry_title {
                html
              }
              entry_text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
